import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert } from "@mui/material";
import ReactGA from "react-ga4";

import "./App.scss";
import Router from "./pages/Router";
import LoadSpinner from "./components/Common/LoadSpinner";
import useAuth from "./operations/useAuth";
import usePortalLookAndFeel from "./operations/usePortalLookAndFeel";
import ToastProvider from "./providers/ToastProvider";
import { usePortalLookAndFeelStorage } from "./hooks/usePortalLookAndFeelStorage";
import UnderMaintenance from "./pages/UnderMaintenance";

const App = () => {
  const {
    application,
    authenticateWithoutCredentials,
    authenticateWithCredentials,
    isLoading: isAuthLoading,
    error: authError,
  } = useAuth();

  const {
    portalLookAndFeel,
    load: loadPortalLookAndFeel,
    error: portalLookAndFeelError,
  } = usePortalLookAndFeel();
  const { hasPortalLookAndFeelFromLocalStorage } =
    usePortalLookAndFeelStorage();

  const [isPortalLookAndFeelLoading, setPortalLookAndFeelLoading] = useState(
    !hasPortalLookAndFeelFromLocalStorage
  );

  const isLoading = useMemo(
    () => isAuthLoading || isPortalLookAndFeelLoading,
    [isAuthLoading, isPortalLookAndFeelLoading]
  );

  const error = useMemo(
    () => authError || portalLookAndFeelError,
    [authError, portalLookAndFeelError]
  );

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const zipCode = params.get("zip-code");
    const orderNumber = params.get("order-number");
    if (!zipCode && !orderNumber) {
      authenticateWithoutCredentials();
    } else if (!hasPortalLookAndFeelFromLocalStorage) {
      authenticateWithCredentials();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchPortalLookAndFeel = async () => {
      if (application) {
        await loadPortalLookAndFeel(
          isPortalLookAndFeelLoading,
          setPortalLookAndFeelLoading
        );
        setPortalLookAndFeelLoading(false);
      }
    };
    fetchPortalLookAndFeel();
    // eslint-disable-next-line
  }, [application]);

  // dont remove
  // eslint-disable-next-line
  console.log("version", "0.9.1");

  if (error) return <Alert severity="error">{error.message}</Alert>;

  if (isLoading) return <LoadSpinner />;

  ReactGA.initialize([
    {
      trackingId: String(process.env.REACT_APP_GA_MEASUREMENT_ID),
    },
    {
      trackingId: String(
        portalLookAndFeel.custrecord_ucp_google_analytics_code
      ),
    },
  ]);

  if (application?.underMaintenance) {
    return (
      <UnderMaintenance
        underMaintenanceMessage={portalLookAndFeel.underMaintenance}
      />
    );
  }

  const isOrderStatusPage = window?.location.host.includes(
    "orderstatus.unlockcommerce.co"
  ) ? (
    <meta name="robots" content="noindex,nofollow" />
  ) : null;

  return (
    <ToastProvider>
      <Helmet>
        <link rel="icon" href={portalLookAndFeel.favicon} />
        <meta name="description" content={portalLookAndFeel.slogan} />
        {isOrderStatusPage}
        <title>{portalLookAndFeel.title}</title>
      </Helmet>
      <Router />
    </ToastProvider>
  );
};

export default App;
