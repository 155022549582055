import pSBC from "shade-blend-color";
import Entity, { EntityOptions } from "./Entity";

export type PortalLookAndFeelRecord = {
  id: string;
  custrecord_ucp_returns_logo?: string;
  custrecord_ucp_returns_favicon?: string;
  custrecord_ucp_returns_bckg_image?: string;
  custrecord_ucp_returns_bckg_color?: string;
  custrecord_ucp_returns_text_color?: string;
  custrecord_ucp_returns_button_shape?: string;
  custrecord_ucp_retruns_button_style?: string;
  custrecord_ucp_returns_primary_color?: string;
  custrecord_ucp_returns_headings_color?: string;
  custrecord_ucp_header_text_1?: string;
  custrecord_ucp_header_link_1?: string;
  custrecord_ucp_header_text_2?: string;
  custrecord_ucp_header_link_2?: string;
  custrecord_ucp_header_text_3?: string;
  custrecord_ucp_header_link_3?: string;
  custrecord_ucp_returns_title?: string;
  custrecord_ucp_returns_company_name?: string;
  custrecord_ucp_returns_slogan?: string;
  custrecord_ucp_returns_footer_s1_title?: string;
  custrecord_ucp_returns_footer_s1_text_1?: string;
  custrecord_ucp_returns_footer_s1_link_1?: string;
  custrecord_ucp_returns_footer_s1_text_2?: string;
  custrecord_ucp_returns_footer_s1_link_2?: string;
  custrecord_ucp_returns_footer_s1_text_3?: string;
  custrecord_ucp_returns_footer_s1_link_3?: string;
  custrecord_ucp_returns_footer_s2_text_1?: string;
  custrecord_ucp_returns_footer_s2_link_1?: string;
  custrecord_ucp_returns_footer_s2_title?: string;
  custrecord_ucp_returns_footer_s2_text_2?: string;
  custrecord_ucp_returns_footer_s2_link_2?: string;
  custrecord_ucp_returns_footer_s2_text_3?: string;
  custrecord_ucp_returns_footer_s2_link_3?: string;
  custrecord_ucp_returns_footer_s3_title?: string;
  custrecord_ucp_returns_footer_s3_text_1?: string;
  custrecord_ucp_returns_footer_s3_link_1?: string;
  custrecord_ucp_returns_footer_s3_text_2?: string;
  custrecord_ucp_returns_footer_s3_link_2?: string;
  custrecord_ucp_returns_footer_s3_text_3?: string;
  custrecord_ucp_returns_footer_s3_link_3?: string;
  custrecord_ucp_returns_footer_text_1?: string;
  custrecord_ucp_returns_footer_link_1?: string;
  custrecord_ucp_returns_footer_text_2?: string;
  custrecord_ucp_returns_footer_link_2?: string;
  custrecord_ucp_returns_footer_text_3?: string;
  custrecord_ucp_returns_footer_link_3?: string;
  custrecord_ucp_returns_help_link?: string;
  custrecord_ucp_returns_footer_image?: string;
  custrecord_ucp_returns_your_account_link?: string;
  custrecord_ucp_html_text?: string;
  custrecord_ucp_top_banner_text?: string;
  custrecord_ucp_image_banner?: string;
  custrecord_ucp_html_banner_mobile?: string;
  custrecord_ucp_image_banner_mobile?: string;
  custrecord_ucp_image_banner_link?: string;
  custrecord_ucp_image_banner_mobile_link?: string;
  custrecord_ucp_returns_tc_link?: string;
  custrecord_ucp_google_analytics_code?: string;
  custrecord_ucp_navbar_background_color?: string;
  custrecord_ucp_navbar_link_color?: string;
  custrecord_ucp_home_title_text?: string;
  custrecord_ucp_home_title_color?: string;
  custrecord_ucp_home_description_text?: string;
  custrecord_ucp_home_title_text_shipping?: string;
  custrecord_ucp_home_title_color_shipping?: string;
  custrecord_ucp_home_desc_text_shipping?: string;
  [key: string]: string | undefined;
  custrecord_ucp_under_maintenance: string;
  custrecord_ucp_logo_url: string;
};

export type LinkObject = {
  text: string;
  url: string;
  key: string;
};

export type PortalLookAndFeelOptions = EntityOptions & {
  logo?: string;
  favicon?: string;
  backgroundImage?: string;
  backgroundColor?: string;
  textColor?: string;
  buttonShape?: string;
  buttonStyle?: string;
  primaryLightestColor?: string;
  primaryLighterColor?: string;
  primaryLightColor?: string;
  primaryColor?: string;
  primaryDarkColor?: string;
  primaryDarkerColor?: string;
  primaryDarkestColor?: string;
  headingsColor?: string;
  headerLinks?: LinkObject[];
  slogan?: string;
  footerLinks?: LinkObject[];
  footerSections?: {
    title: string;
    links: LinkObject[];
  }[];
  helpLink?: string;
  footerImage?: string;
  title?: string;
  yourAccount?: string;
  homeBannerHtml?: string;
  topBannerText?: string;
  imageBanner?: string;
  mobileHtmlBanner?: string;
  mobileImageBanner?: string;
  imageBannerLink?: string;
  mobileImageBannerLink?: string;
  termsAndConditionsLink?: string;
  googleAnalyticsCode?: string;
  navbarBackground?: string;
  navbarLinkColor?: string;
  homeTitleText?: string;
  homeTitleColor?: string;
  homeDescriptionText?: string;
  homeTitleTextShipping?: string;
  homeTitleColorShipping?: string;
  homeDescriptionTextShipping?: string;
  logoUrl?: string;
};

export default class PortalLookAndFeel extends Entity<PortalLookAndFeelOptions> {
  logo?: string;

  favicon?: string;

  backgroundImage?: string;

  backgroundColor?: string;

  textColor?: string;

  buttonShape?: string;

  buttonStyle?: string;

  primaryLightestColor?: string;

  primaryLighterColor?: string;

  primaryLightColor?: string;

  primaryColor?: string;

  primaryDarkColor?: string;

  primaryDarkerColor?: string;

  primaryDarkestColor?: string;

  headingsColor?: string;

  headerLinks?: LinkObject[];

  title?: string;

  companyName?: string;

  slogan?: string;

  footerLinks?: LinkObject[];

  footerSections?: {
    title: string;
    links: LinkObject[];
  }[];

  helpLink?: string;

  footerImage?: string;

  yourAccount?: string;

  homeBannerHtml?: string;

  topBannerText?: string;

  imageBanner?: string;

  mobileHtmlBanner?: string;

  mobileImageBanner?: string;

  imageBannerLink?: string;

  mobileImageBannerLink?: string;

  termsAndConditionsLink?: string;

  googleAnalyticsCode?: string;

  navbarBackground?: string;

  navbarLinkColor?: string;

  homeTitleText?: string;

  homeTitleColor?: string;

  homeDescriptionText?: string;

  homeTitleTextShipping?: string;

  homeTitleColorShipping?: string;

  homeDescriptionTextShipping?: string;

  underMaintenance?: string;

  logoUrl?: string;

  constructor(options: PortalLookAndFeelOptions = {}) {
    const optionsWithDefaults = {
      ...options,
      logo: options.logo,
      favicon: options.favicon,
      backgroundColor: options.backgroundColor,
      primaryColor: options.primaryColor,
      headingsColor: options.headingsColor,
      title: options.title,
      companyName: options.companyName,
      textColor: options.textColor,
      footerImage: options.footerImage,
      primaryLightestColor: "",
      primaryLighterColor: "",
      primaryLightColor: "",
      primaryDarkColor: "",
      primaryDarkerColor: "",
      primaryDarkestColor: "",
    };
    optionsWithDefaults.primaryLightestColor =
      (options.primaryLightestColor ||
        pSBC(0.85, String(optionsWithDefaults.primaryColor))) ??
      "";
    optionsWithDefaults.primaryLighterColor =
      (options.primaryLighterColor ||
        pSBC(0.5, String(optionsWithDefaults.primaryColor))) ??
      "";
    optionsWithDefaults.primaryLightColor =
      (options.primaryLightColor ||
        pSBC(0.2, String(optionsWithDefaults.primaryColor))) ??
      "";
    optionsWithDefaults.primaryDarkColor =
      (options.primaryDarkColor ||
        pSBC(-0.2, String(optionsWithDefaults.primaryColor))) ??
      "";
    optionsWithDefaults.primaryDarkerColor =
      (options.primaryDarkerColor ||
        pSBC(-0.5, String(optionsWithDefaults.primaryColor))) ??
      "";
    optionsWithDefaults.primaryDarkestColor =
      (options.primaryDarkestColor ||
        pSBC(-0.6, String(optionsWithDefaults.primaryColor))) ??
      "";
    super(optionsWithDefaults);
    this.set(optionsWithDefaults);
  }

  static parseLink(text?: string, url?: string, key?: string) {
    if (text && url) {
      return {
        text,
        url,
        key,
      };
    }

    return undefined;
  }

  static parseHeaderLink(link: number, result: PortalLookAndFeelRecord) {
    const text = result && result[`custrecord_ucp_header_text_${link}`];
    const url = result && result[`custrecord_ucp_header_link_${link}`];
    const keyText = result && result[`custrecord_ucp_header_text_${link}`];

    if (keyText) {
      const keyFormatted = keyText.replace(/\s/g, "");
      const key = keyFormatted.charAt(0).toLowerCase() + keyFormatted.slice(1);
      return PortalLookAndFeel.parseLink(text, url, key);
    }

    return PortalLookAndFeel.parseLink(text, url, "");
  }

  static parseHeaderLinks(result: PortalLookAndFeelRecord) {
    const headerLinks = [1, 2, 3]
      .map((link) => PortalLookAndFeel.parseHeaderLink(link, result))
      .filter((headerLink) => headerLink) as LinkObject[];

    return headerLinks.length ? headerLinks : undefined;
  }

  static parseFooterLink(link: number, result: PortalLookAndFeelRecord) {
    const text = result && result[`custrecord_ucp_returns_footer_text_${link}`];
    const url = result && result[`custrecord_ucp_returns_footer_link_${link}`];

    return PortalLookAndFeel.parseLink(text, url);
  }

  static parseFooterLinks(result: PortalLookAndFeelRecord) {
    const footerLinks = [1, 2, 3]
      .map((link) => PortalLookAndFeel.parseFooterLink(link, result))
      .filter((footerLink) => footerLink) as LinkObject[];
    return footerLinks.length ? footerLinks : undefined;
  }

  static parseFooterSectionLink(
    section: number,
    link: number,
    result: PortalLookAndFeelRecord
  ) {
    const text =
      result &&
      result[`custrecord_ucp_returns_footer_s${section}_text_${link}`];
    const url =
      result &&
      result[`custrecord_ucp_returns_footer_s${section}_link_${link}`];

    return PortalLookAndFeel.parseLink(text, url);
  }

  static parseFooterSection(section: number, result: PortalLookAndFeelRecord) {
    const title =
      result && result[`custrecord_ucp_returns_footer_s${section}_title`];

    if (title) {
      const links = [1, 2, 3]
        .map((link) =>
          PortalLookAndFeel.parseFooterSectionLink(section, link, result)
        )
        .filter((sectionLink) => sectionLink);
      return {
        title,
        links,
      };
    }

    return undefined;
  }

  static parseFooterSections(result: PortalLookAndFeelRecord) {
    const footerSections = [1, 2, 3]
      .map((section) => PortalLookAndFeel.parseFooterSection(section, result))
      .filter((section) => section) as {
      title: string;
      links: LinkObject[];
    }[];
    return footerSections.length ? footerSections : undefined;
  }

  static parsePortalLookAndFeelRecord(result: PortalLookAndFeelRecord) {
    return new PortalLookAndFeel({
      id: result.id,
      logo: result?.custrecord_ucp_returns_logo,
      favicon: result?.custrecord_ucp_returns_favicon,
      backgroundImage: result?.custrecord_ucp_returns_bckg_image,
      backgroundColor: result?.custrecord_ucp_returns_bckg_color,
      textColor: result?.custrecord_ucp_returns_text_color,
      buttonShape: result?.custrecord_ucp_returns_button_shape,
      buttonStyle: result?.custrecord_ucp_retruns_button_style,
      primaryColor: result?.custrecord_ucp_returns_primary_color,
      headingsColor: result?.custrecord_ucp_returns_headings_color,
      headerLinks: PortalLookAndFeel.parseHeaderLinks(result),
      title: result?.custrecord_ucp_returns_title,
      companyName: result?.custrecord_ucp_returns_company_name,
      slogan: result?.custrecord_ucp_returns_slogan,
      footerLinks: PortalLookAndFeel.parseFooterLinks(result),
      footerSections: PortalLookAndFeel.parseFooterSections(result),
      helpLink: result?.custrecord_ucp_returns_help_link,
      footerImage: result?.custrecord_ucp_returns_footer_image,
      yourAccount: result?.custrecord_ucp_returns_your_account_link,
      homeBannerHtml: result?.custrecord_ucp_html_text,
      topBannerText: result?.custrecord_ucp_top_banner_text,
      imageBanner: result?.custrecord_ucp_image_banner,
      mobileHtmlBanner: result?.custrecord_ucp_html_banner_mobile,
      mobileImageBanner: result?.custrecord_ucp_image_banner_mobile,
      imageBannerLink: result?.custrecord_ucp_image_banner_link,
      mobileImageBannerLink: result?.custrecord_ucp_image_banner_mobile_link,
      termsAndConditionsLink: result?.custrecord_ucp_returns_tc_link,
      googleAnalyticsCode: result?.custrecord_ucp_google_analytics_code,
      navbarBackground: result?.custrecord_ucp_navbar_background_color,
      navbarLinkColor: result?.custrecord_ucp_navbar_link_color,
      homeTitleText: result.custrecord_ucp_home_title_text,
      homeTitleColor: result.custrecord_ucp_home_title_color,
      homeDescriptionText: result.custrecord_ucp_home_description_text,
      homeTitleTextShipping: result.custrecord_ucp_home_title_text_shipping,
      homeTitleColorShipping: result.custrecord_ucp_home_title_color_shipping,
      homeDescriptionTextShipping:
        result.custrecord_ucp_home_desc_text_shipping,
      underMaintenance: result?.custrecord_ucp_under_maintenance,
      logoUrl: result?.custrecord_ucp_logo_url,
    });
  }
}
