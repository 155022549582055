import { useCallback, useMemo } from "react";
import { HttpsCallable, httpsCallable } from "firebase/functions";

import useFunctionsService from "./useFunctionsService";
import { ApplicationOptions } from "../entities/Application";
import Shipment from "../entities/Shipment";
import ShipmentStatus from "../enums/ShipmentStatus";

export type ShipmentRecord = {
  id: string;
  name: string;
  custrecord_ucp_shipment_signed_by: string;
  custrecord_ucp_ship_to_zip_code: string;
  custrecord_ucp_order_number: string;
  custrecord_ucp_shipment_item_fulfill: string;
  custrecord_ucp_shipment_status: ShipmentStatus;
  custrecord_ucp_shipment_weight: string;
  custrecord_ucp_shipment_carrier: { name: string };
  custrecord_ucp_shipment_created_at: string;
  custrecord_ucp_shipment_updated_at: string;
  custrecord_ucp_shipment_mode: string;
  custrecord_ucp_shipment_total_transit: string;
  custrecord_ucp_shipment_transit_times_seg: string;
  custrecord_ucp_sms: string;
  custrecord_ucp_email: string;
  custrecord_ucp_other_reference: string;
  custrecord_ucp_shipment_on_time: string;
  custrecord_ucp_shipment_latest_event: string;
  custrecord_ucp_shipment_ship_address: string;
  custrecord_ucp_shipment_name: string;
  custrecord_ucp_shipment_est_delivery_d: string;
  custrecord_ucp_shipment_public_url: string;
  custrecord_ucp_carrier_is_not_supported: boolean;
};

const useAuthService = () => {
  const { functions } = useFunctionsService();

  const authenticateFunction: HttpsCallable<
    { domain: string; orderNumber?: string; zipCode?: string },
    {
      statusCode: number;
      message: string;
      token: string;
      application: ApplicationOptions;
      shipments: ShipmentRecord[];
    }
  > = useMemo(
    () => httpsCallable(functions, "unlockshippings-authenticate"),
    [functions]
  );

  const authenticate = useCallback(
    async (domain: string, orderNumber?: string, zipCode?: string) => {
      let shipments;
      let rest;
      try {
        const { shipments: shipmentsProp, ...restProp } = (
          await authenticateFunction({
            domain,
            orderNumber,
            zipCode,
          })
        ).data;
        shipments = shipmentsProp;
        rest = restProp;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }

      if (rest.statusCode === 404) {
        throw new Error(
          rest?.message
            ? rest.message
            : "Sorry, we were unable to locate your shipment. Please ensure your order number and zip code are correct. If you continue to experience difficulties, it might be that your order is still being processed and not yet shipped."
        );
      }

      if (rest.statusCode !== 200) {
        throw new Error("An unexpected error occurred, try again later!");
      }

      return {
        ...rest,
        shipments: shipments
          ? shipments.map(
              (shipment) =>
                new Shipment({
                  id: shipment.id,
                  trackingNumber: shipment?.name,
                  zipCode: shipment?.custrecord_ucp_ship_to_zip_code,
                  order: shipment?.custrecord_ucp_order_number,
                  status: shipment?.custrecord_ucp_shipment_status,
                  carrier: shipment?.custrecord_ucp_shipment_carrier.name,
                  sms: shipment?.custrecord_ucp_sms,
                  email: shipment?.custrecord_ucp_email,
                  latestEvent: shipment?.custrecord_ucp_shipment_latest_event,
                  shipAddress: shipment?.custrecord_ucp_shipment_ship_address,
                  shipmentName: shipment?.custrecord_ucp_shipment_name,
                  expectedDelivery:
                    shipment?.custrecord_ucp_shipment_est_delivery_d,
                  publicUrl: shipment?.custrecord_ucp_shipment_public_url,
                  carrierIsNotSupported:
                    shipment?.custrecord_ucp_carrier_is_not_supported,
                })
            )
          : undefined,
      };
    },
    [authenticateFunction]
  );

  return { authenticate };
};

export default useAuthService;
